/* eslint-disable @next/next/no-img-element */
import { useState } from "react";
import Head from "next/head";
import { Roboto_Mono } from "next/font/google";
import Link from "next/link";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import styles from "@/styles/homepage/Home.module.css";

import { AppStoreNew, AppStoreNewSmall } from "@/components/home/Appstore";
import HamburgerMenu from "@/components/home/HamburgerMenu";
import PublicFooter from "@/components/publicProfiles/Footer/PublicFooter";
import AndroidStore, {
  AndroidStoreSmall,
} from "@/components/home/AndroidStore";

import dimensionData from "../assets/traitInfo/dimensions.json";
import { dimensionIcons } from "@/assets/traitInfo/dimensionData";

const roboto = Roboto_Mono({
  weight: "400",
  subsets: ["latin"],
});

export default function Home() {
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);

  const mapped = dimensionData.map((d) => {
    return (
      <Link
        target="__blank"
        href={`/dimensions/${d.slug}`}
        className={styles.dimensionBlock}
        key={d.slug}
      >
        <div className={styles.dimensionIconDiv}>
          {dimensionIcons[d.slug as keyof typeof dimensionIcons].iconDark}
        </div>
        <p className={`${roboto.className} ${styles.dimensionBlockTitle}`}>
          {d.title}
        </p>
      </Link>
    );
  });

  const mappedSlides = [
    <Image
      key={0}
      src={`/HomePhoneSlideOne.png`}
      alt="Example image of app"
      width={325}
      height={624}
      quality={100}
    />,
    <Image
      key={1}
      src={`/HomePhoneSlideTwo.png`}
      alt="Example image of app"
      width={325}
      height={624}
      quality={100}
    />,
    <Image
      key={2}
      src={`/HomePhoneSlideThree.png`}
      alt="Example image of app"
      width={325}
      height={624}
      quality={100}
    />,
  ].map((c, i) => {
    return <SwiperSlide key={i}>{c}</SwiperSlide>;
  });

  function displayNav() {
    const nav = document.getElementById("nav");
    const hamburger = document.getElementById("hamburger");
    if (nav) {
      if (nav.style.height === "170px") {
        nav.style.height = "0";
        nav.style.padding = "0";
      } else {
        nav.style.height = "170px";
        nav.style.padding = "2% 0";
      }
    }

    if (hamburger) {
      if (hamburger.style.backgroundColor !== "gray") {
        hamburger.style.backgroundColor = "gray";
        hamburger.style.backgroundImage = "none";
      } else {
        hamburger.style.backgroundColor = "";
      }
    }
  }

  return (
    <>
      <Head>
        <title>
          Dimensional - A personality test to get to know yourself and your
          friends
        </title>
        <meta
          name="description"
          content="Know yourself. Connect with others. Improve your life and relationships with the Dimensional personality test."
        />
        <meta
          content="Dimensional - A personality test to get to know yourself and your friends"
          property="og:title"
        />
        <meta
          content="Dimensional - A personality test to get to know yourself and your friends"
          property="twitter:title"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="google-site-verification"
          content="7YTx0vSjMHrHoYbXVlSfQRXvZoIxButUVL-bsIUUjI4"
        />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <div className={styles.main}>
        <section className={styles.tilesImage}>
          <div className={styles.mobileNavParent}>
            <div className={styles.mobileLogoParent}>
              <Link href={"/"}>
                <img
                  src="https://global-uploads.webflow.com/5ddd2caed93ad8355b40f958/62656cd5e8ee77d13eec117d_dimensional-logo.png"
                  loading="lazy"
                  width="100"
                  alt="Dimensional"
                />
              </Link>
            </div>
            <div
              className={styles.hamburger}
              id="hamburger"
              onClick={(e) => {
                displayNav();
              }}
            >
              <HamburgerMenu />
            </div>
          </div>

          <nav id="nav" className={styles.nav}>
            <div className={styles.navParent}>
              <div className={styles.navLeft}>
                <div className={styles.logoParent}>
                  <Link href={"/"}>
                    <img
                      src="https://global-uploads.webflow.com/5ddd2caed93ad8355b40f958/62656cd5e8ee77d13eec117d_dimensional-logo.png"
                      loading="lazy"
                      width="100"
                      alt="Dimensional"
                    />
                  </Link>
                </div>
              </div>
              <div className={styles.navRightParent}>
                <div className={`${styles.navChild} ${styles.firstNav}`}>
                  <Link href={"/"}>Home</Link>
                </div>
                <div className={styles.navChild}>
                  <a href="https://about.dimensional.me/blog">Blog</a>
                </div>
                <div className={styles.navChild}>
                  <a href="https://about.dimensional.me/about">About</a>
                </div>
              </div>
            </div>
          </nav>
          <div className={styles.top}>
            <div className={styles.topDiv}>
              <h1 className={styles.title}>Know yourself (and your friends)</h1>
              <h1 className={styles.titleMobile}>Know yourself</h1>
              <h1 className={styles.titleMobile}>(and your friends)</h1>
              <h2 className={styles.subtitle}>
                Personality testing that reveals your true self, compares you to
                your inner circle, and calls you out in the best way.
              </h2>
              <div className={styles.downloadButtonsDiv}>
                <AppStoreNew link />
                <AndroidStore />
              </div>
              <div className={styles.downloadButtonsDivMobile}>
                <AppStoreNewSmall link />
                <AndroidStoreSmall />
              </div>
            </div>
          </div>
          <div className={styles.topPhoneImage}>
            <Image
              fill
              alt="Dimensional Phone Image Examples"
              src={`/HomePagePhoneImage.png`}
            />
          </div>
        </section>

        <section className={styles.mid}>
          <div className={styles.midParent}>
            <div className={styles.midLeft}>
              <h3 className={styles.midLeftTitle}>Understand yourself in HD</h3>
              <p className={`${roboto.className} ${styles.midLeftSubtitle}`}>
                Dimensional measures over 200 traits across 15 dimensions of
                personality to create your unique personality profile.
              </p>
            </div>
            <div className={styles.midRight}>{mapped}</div>
          </div>
          <div className={styles.midBottom}>
            <Image
              alt="List of Dimensional traits."
              src={`/HomeMidBottomImage.png`}
              fill
              priority
              className={styles.midBottomImage}
            />
          </div>
        </section>

        <section className={styles.freakishSection}>
          <div className={styles.freakishBackgroundImage}>
            <Image
              fill
              alt="Dimensional Background Image"
              src={`/HomeFreakishBgImage.png`}
            />
          </div>
          <h2
            className={styles.freakishTitle}
          >{`"Freakishly accurate" content delivered daily`}</h2>
          <h3 className={`${styles.freakishSubTitle} ${roboto.className}`}>
            {
              "You're the star of your inner world and Dimensional is your paparazzi."
            }
          </h3>
          <div className={styles.freakishImage}>
            <Image
              priority
              src={`/HomeFreakishImage.png`}
              alt="Example Images of app"
              width={961}
              height={673}
              quality={100}
            />
          </div>
          <div className={styles.freakishImageMobile}>
            <Image
              priority
              src={`/HomeFreakishImage.png`}
              alt="Example Images of app"
              fill
              quality={100}
            />
          </div>
        </section>

        <section className={styles.connectSection}>
          <div className={styles.connectWrapper}>
            <div className={styles.connectLeft}>
              <h3 className={styles.midLeftTitle}>Connect with friends</h3>
              <p className={`${roboto.className} ${styles.midLeftSubtitle}`}>
                Compare personalities, explore compatibility, and unlock a
                wealth of information about your relationship.
              </p>
            </div>
            <div className={styles.connectRight}>
              <Swiper
                autoHeight={true}
                slidesPerView={1}
                touchReleaseOnEdges={true}
                onSlideChange={(e) => {
                  setIndex(e.realIndex);
                }}
                loop
                scrollbar={{ draggable: true }}
                threshold={1}
                onSwiper={setMySwiperRef}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onAutoplay={(e) => {
                  setIndex(e.realIndex);
                }}
                modules={[Autoplay]}
              >
                {mappedSlides}
              </Swiper>
              <div className={styles.circleDiv}>
                <div
                  className={
                    index === 0 ? styles.circleActive : styles.circleInactive
                  }
                ></div>
                <div
                  className={
                    index === 1 ? styles.circleActive : styles.circleInactive
                  }
                ></div>
                <div
                  className={
                    index === 2 ? styles.circleActive : styles.circleInactive
                  }
                ></div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.bottomSection}>
          <h3 className={styles.bottomSectionTitle}>
            Start your journey today
          </h3>

          <div className={styles.BottomButtonsDiv}>
            <AppStoreNew link />
            <AndroidStore />
          </div>
          <div className={styles.BottomButtonsDivMobile}>
            <AppStoreNewSmall link />
            <AndroidStoreSmall />
          </div>
        </section>
        <PublicFooter />
      </div>
    </>
  );
}
