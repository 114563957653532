import styles from "@/styles/homepage/Home.module.css";

export default function HambuMenu() {
  return (
    <div className={styles.hamburgerMenuParent}>
      <div className={styles.hamburgerMenuChild}></div>
      <div className={styles.hamburgerMenuChild}></div>
      <div className={styles.hamburgerMenuChild}></div>
    </div>
  );
}
