/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import styles from "./PublicFooter.module.css";

export default function PublicFooter() {
  return (
    <footer className={styles.footerMain}>
      <div className={styles.wrapper}>
        <div className={styles.footerChild}>
          <Link href={"/"}>
            <img
              src="https://global-uploads.webflow.com/5ddd2caed93ad8355b40f958/62656cd5e8ee77d13eec117d_dimensional-logo.png"
              loading="lazy"
              width="100"
              alt="Dimensional"
            />
          </Link>
        </div>
        <div className={styles.footerChild}>
          <Link href={"https://about.dimensional.me/about"}>
            <h4>About us</h4>
          </Link>
          <Link href={"https://about.dimensional.me/about"}>
            <h5>About Dimensional</h5>
          </Link>
          <h5>Jobs</h5>
          <Link href={"https://about.dimensional.me/personality-test"}>
            <h5>Personality Test</h5>
          </Link>
          <Link href={"https://about.dimensional.me/privacy-policy"}>
            <h5>Privacy Policy</h5>
          </Link>
          <Link href={"https://about.dimensional.me/terms-of-service"}>
            <h5>Terms of Use</h5>
          </Link>
          <Link href={"/traits"}>
            <h5>Traits</h5>
          </Link>
        </div>
        <div className={styles.footerChild}>
          <h4>Social</h4>
          <Link
            target={"_blank"}
            href={"https://www.instagram.com/dimensional.me"}
          >
            <h5>Insta: @dimensional.me</h5>
          </Link>
          <Link
            target={"_blank"}
            href={"https://www.tiktok.com/@dimensional.me"}
          >
            <h5>TikTok: @dimensional.me</h5>
          </Link>
        </div>
        <div className={styles.footerChild}>
          <h4>Contact</h4>
          <h5>sfard@dimensional.me</h5>
          <br />
          <h5>Dimensional Interactive 1050 King Street West Toronto, ON</h5>
        </div>
      </div>
    </footer>
  );
}
